<script setup>
import { ref } from 'vue'
import sdk from '@megaport/api-sdk'
import integrations from '@/third-party-integrations/integrations'

import { useRoute } from 'vue-router/composables'
import { showModalAlert } from '@/components/authentication/AuthHelpers'


const isLoading = ref(true)
federate()

async function federate() {
  const email = useRoute().query.email || ''
  if (!email) {
    showModalAlert(window.mpApp.$t('authentication.email-query-missing'), window.mpApp.$t('authentication.failed'), 'error')
    isLoading.value = false
    return
  }

  try {
    const { status, idp } = await sdk.instance.federate(email)
    if (status !== 'SSO') {
      showModalAlert(window.mpApp.$t('authentication.email-not-sso'), window.mpApp.$t('authentication.failed'), 'error')
      return
    }
    await integrations.awsAmplify.federatedSignIn({ customProvider: idp.name })
  } catch (failObj) {
    if (failObj.handled) return

    console.error(failObj)

    let errorMessage = ''
    let errorLevel = 'error'
    if (failObj.status === 500) {
      errorMessage = window.mpApp.$t('general.unexpected-error')
    } else if (failObj.status === 401 || failObj.data?.message) {
      errorMessage = failObj.data?.message
    }

    showModalAlert(errorMessage, window.mpApp.$t('authentication.failed'), errorLevel)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div v-loading.fullscreen="isLoading"
    data-testid="sso-login-spinner" />
</template>
